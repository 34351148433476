import {style} from "@vanilla-extract/css";

export const previews = style({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "8px",
    overflowY: "auto",
    maxHeight: "90vh",
    backgroundColor: "lightgray",
    border: "gray 1px/0 solid",
    padding: "8px",
    margin: "8px -8px"
});
