import React, {useEffect, useState} from "react";
import {extractFiles} from "~/app/utils/fileUtils";
import type {Pdf} from "~/app/components/FileManager";
import { AddCircle } from '@mui/icons-material';
import { Box } from "@mui/material";

type Props = {
    addPdfs: (pdfs: Pdf[]) => void;
    more: boolean;
}
const PdfLoader: React.FC<Props> = ({addPdfs, more}) => {
    const [loading, setLoading] = useState(false);

    async function filesHandler(files: File[]) {
        const {getDocument, GlobalWorkerOptions} =  await import("pdfjs-dist");
        GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');

        const documents = await Promise.all(files.map(async (file) => {
            const ab = await file.arrayBuffer();
            return await getDocument({data: ab}).promise;
        }));

        addPdfs(documents.map((doc, i) => ({
            file: files[i],
            pdfJsDoc: doc,
            fileName: files[i].name
        })));
    }

    useEffect(() => {
        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            document.addEventListener(eventName, preventDefaults, false)
        })

        function preventDefaults(e: any) {
            e.preventDefault()
            e.stopPropagation()
        }

        document.addEventListener("drop", dropHandler);

        async function dropHandler(this: any, ev: any) {
            ev.preventDefault();
            if (loading) {
                alert("ファイル読み込み中に新たなファイルの追加はできません")
                return;
            }
            setLoading(true);
            try {
                const openedFiles = extractFiles(ev.dataTransfer);
                await filesHandler(openedFiles);

                setLoading(false);
            } catch (e) {
                console.error(e)
                setLoading(false);
            }
        }

        return () => {
            ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                document.removeEventListener(eventName, preventDefaults, false)
            })
            document.removeEventListener("drop", dropHandler);
        }
    }, [addPdfs]);

    // TODO: Add a button to open a file dialog
    return (
        <Box as="label" color="primary" sx={{
            width: "100%",
            minHeight: more ? "100px" : "300px",
            border: "5px red dashed",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "40px",
            boxSizing: "border-box",
            margin: "8px 0",
            padding: "16px"
        }}>
            <AddCircle color="primary" fontSize="large" />
            <input id="file" type="file" name="file" style={{display: "none"}} onChange={(e)=>{
                if(!e.target.files) return;
                void filesHandler(Array.from(e.target.files));
            }} />
            {more ? "PDFをさらに追加" : "PDFをドロップ"}</Box>
    )
}

export default PdfLoader;
