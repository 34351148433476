import React, {useEffect, useState} from "react";
import type {Page, Pdf} from "~/app/components/FileManager";
import type {ExportConfig} from "~/app/utils/pdfUtils";
import {generatePdfDocument} from "~/app/utils/pdfUtils";
import Preview from "~/app/components/Preview";
import {stripExtension} from "~/app/utils/fileUtils";
import {PRICE_TABLE, Size} from "~/app/utils/utils";
import {Button, Fab, Tooltip} from "@mui/material";
import {SaveAlt} from '@mui/icons-material';

type Exported = {
    url: string,
    fileName: string,
    pages: number,
    size: Size,
}

const fabStyle = {
    position: 'fixed',
    bottom: 16,
    right: 16,
};

type Props = {
    pdfs: Pdf[],
    pages: Page[],
    config: ExportConfig
}
const Exporter: React.FC<Props> = ({pdfs, pages, config}) => {
    const [exported, setExported] = useState<Exported | null>(null);

    useEffect(() => {
        if (exported) {
            URL.revokeObjectURL(exported.url);
        }
        save();
    }, [pdfs, pages, config])

    async function save() {
        const {doc, fileName, size} = await generatePdfDocument(pdfs, pages, config);

        const barray = await doc.save();
        const url = URL.createObjectURL(new Blob([barray], {type: "application/pdf"}));
        const output = {url, fileName, pages: doc.getPages().length, size};
        setExported(output);
        return output;
    }

    function download(output: Exported) {
        const downloadLink = document.createElement("a");
        downloadLink.href = output.url;
        var result = prompt("ファイル名を指定してダウンロード", stripExtension(output.fileName));
        if (!result) {
            return;
        }
        downloadLink.download = result + ".pdf";
        downloadLink.click();
    }

    const priceBefore = pages.length * PRICE_TABLE[Size.A4];
    const priceAfter = exported ? PRICE_TABLE[exported.size] * exported.pages : NaN;

    return exported ?
        <>
            <Tooltip title="PDFを保存" sx={fabStyle}>
                <Fab color="primary" aria-label="add" size="large" onClick={async () => download(exported)}>
                    <SaveAlt/>
                </Fab>
            </Tooltip>
            <Button variant="contained" color={"primary"} startIcon={<SaveAlt/>}
                    onClick={async () => download(exported)}>
                PDFを保存
            </Button>
            概算：{priceBefore}円 → {isNaN(priceAfter) ? "?" :
            <b>{priceAfter}円 {displayDiscount(priceBefore, priceAfter)}🎉</b>}
            <Preview url={exported.url}/>
        </> : <div style={{display: "inline-block", width: "100%", aspectRatio: "1/1.41", maxHeight: "100vh"}}></div>;
}

export default Exporter;

function displayDiscount(priceBefore: number, priceAfter: number) {
    const discount = priceBefore - priceAfter;
    const discountRate = discount / priceBefore * 100;
    if (discountRate > 20) {
        return `約${(discountRate / 10).toFixed(0)}割引`
    }
    return `約${discountRate.toFixed(0)}%引`
}
