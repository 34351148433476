import FileManager from "~/app/components/FileManager";
import Banners from "~/app/components/Banners";

/*
export const meta: V2_MetaFunction = () => {
    return [
        {title: "PDFシキツメ - コンビニプリントを半額に！"},
        {name: "description", content: "PDFをA3に敷き詰めてコンビニ印刷代を節約"},
    ];
};*/

export default function Index() {
    return (
        <div style={{fontFamily: "system-ui, sans-serif", lineHeight: "1.8"}}>
            <Banners />
            <FileManager/>
            <h3>使い方</h3>
            <ol>
                <li>PDFファイルを読み込みます</li>
                <li>プレビューを確認しPDFを保存します</li>
                <li>あとは、保存したPDFを印刷するだけです。印刷用紙サイズの指示がファイル名の先頭に付与されます（例：[A3片面]）</li>
            </ol>
        </div>
    );
}
