export function extractFiles(dataTransfer: DataTransfer): File[] {
    if (dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        return [...dataTransfer.items]
            .filter(item => item.kind==="file")
            .map(item => item.getAsFile());
    } else {
        // Use DataTransfer interface to access the file(s)
        return [...dataTransfer.files];
    }
}

export function stripExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, "");
}
