import * as styles from "~/app/components/Exporter.css";
import React from "react";

type Props = {
    url: string
}
const Preview: React.FC<Props> = ({url}) => {
    return (
        <iframe src={url} className={styles.iframe} title={"プレビュー"}/>
    )
}

export default Preview
