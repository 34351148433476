import type { PDFPage} from "pdf-lib";
import {degrees, PageSizes, PDFDocument} from "pdf-lib";
import type {Page, Pdf} from "~/app/components/FileManager";
import {stripExtension} from "~/app/utils/fileUtils";
import {Size} from "~/app/utils/utils";

const A3_LANDSCAPE: [number, number] = [PageSizes.A3[1], PageSizes.A3[0]];
const A4_PORTRAIT: [number, number] = PageSizes.A4;

export type ExportConfig = {
    a4toa3: boolean,
    bothSided: boolean,
    whiteFront: boolean,
}
export type ExportResult = {
    doc: PDFDocument,
    fileName: string;
    size: Size
}

export async function generatePdfDocument(pdfs: Pdf[], pages: Page[], config: ExportConfig): Promise<ExportResult> {
    const fileNameBase = `${pdfs.map(pdf => stripExtension(pdf.fileName)).join("-")}.pdf`

    const theDoc = await PDFDocument.create();
    let size: Size;
    const onlyOnePage = pages.filter(page => page.included).length <= 1;
    if (config.a4toa3 && !onlyOnePage) {
        let pagesProcessed = 0;
        for (let i = 0; i < pdfs.length; i++) {
            const pdf = pdfs[i];
            let indices: (number | undefined)[] = pages.filter(page => page.fileIndex === i && page.included).map(page => page.pageIndex);
            if (indices.length === 0) continue;
            if(config.whiteFront) indices = [undefined, ...indices];

            if (config.bothSided) {
                console.log("inputIndices", indices)
                // replace pages from [4*n+1, 4*n+2, 4*n+3, 4*n+4] to [4*n+1, 4*n+3, 4*n+4, 4*n+2]
                const newIndices: (number | undefined)[] = [];
                const normLength = Math.ceil(indices.length / 4) * 4;
                for(let i = 0; i < indices.length/4; i++) {
                    newIndices.push(
                        indices[normLength-i*2-1],
                        indices[i*2],
                        indices[i*2+1],
                        indices[normLength-i*2-2],
                    );
                }
                indices = newIndices;
            }
            console.log({indices})

            const doc = await PDFDocument.load(await pdf.file.arrayBuffer())
            for (const index of indices) {
                let a3Page: PDFPage;
                let pageToEmbed, options;
                if (index !== undefined) {
                    pageToEmbed = await theDoc.embedPage(doc.getPage(index));
                    const isLandscape = pageToEmbed.height / pageToEmbed.width <= 1.1
                    if (isLandscape) {
                        options = {
                            x: A3_LANDSCAPE[0] / 2 - (A4_PORTRAIT[0] - pageToEmbed.height) / 2,
                            y: (A4_PORTRAIT[1] - pageToEmbed.width) / 2,
                            rotate: degrees(90)
                        }
                    } else {
                        options = {
                            x: (A4_PORTRAIT[0] - pageToEmbed.width) / 2,
                            y: (A4_PORTRAIT[1] - pageToEmbed.height) / 2,
                        }
                    }
                }
                if (pagesProcessed % 2 === 0) {
                    a3Page = theDoc.insertPage(theDoc.getPages().length, A3_LANDSCAPE)
                    if (pageToEmbed && options) a3Page.drawPage(pageToEmbed, options)
                } else {
                    if (pageToEmbed && options) {
                        a3Page = theDoc.getPages()[theDoc.getPages().length - 1]
                        a3Page.drawPage(pageToEmbed, {
                            ...options,
                            x: options.x + A3_LANDSCAPE[0] / 2,
                        })
                    }
                }
                pagesProcessed++;
            }
        }
        size = Size.A3;
    } else {
        for (let i = 0; i < pdfs.length; i++) {
            const pdf = pdfs[i];
            const indices = pages.filter(page => page.fileIndex === i && page.included).map(page => page.pageIndex);
            if (indices.length > 0) {
                const copiedPages = await theDoc.copyPages(await PDFDocument.load(await pdf.file.arrayBuffer()), indices);
                copiedPages.forEach(copied => theDoc.addPage(copied))
            }
        }
        size = Size.A4;
    }
    const fileName = `[${size}${config.bothSided ? "両面短辺とじ":"片面"}]-${fileNameBase}`;
    theDoc.setTitle(fileName);
    return {
        doc: theDoc,
        fileName,
        size
    }
}
