import React, {useState} from "react";
import type {PDFPageProxy} from "pdfjs-dist";
import type {PDFDocumentProxy} from "pdfjs-dist/types/src/display/api";
import {PagePreviews} from "~/app/components/PagePreview";
import Exporter from "~/app/components/Exporter";
import PdfLoader from "~/app/components/PdfLoader";
import {FormControlLabel, Switch} from "@mui/material";

export type Page = {
    file: File,
    fileIndex: number,
    pageIndex: number,
    pageProxy: PDFPageProxy,
    included: boolean,
}

export type Pdf = {
    file: File,
    pdfJsDoc: PDFDocumentProxy,
    fileName: string
}

const FileManager = () => {
    const [pdfs, setPdfs] = useState<Pdf[]>([]);
    let [pages, setPages] = useState<Page[]>([]);

    const [pack, setPack] = useState(true);
    const [bothSided, setBothSided] = useState(false);
    const [whiteFront, setWhiteFront] = useState(false);

    return (
        <div>
            <PdfLoader addPdfs={
                (newPdfs) => {
                    setPdfs((pdfs) => [...pdfs, ...newPdfs])

                    const newPages: Promise<Page>[] = [];
                    newPdfs.forEach((pdf, i) => {
                        const doc = pdf.pdfJsDoc;
                        for (let j = 0; j < doc.numPages; j++) {
                            newPages.push(doc.getPage(j + 1).then(page => ({
                                file: pdf.file,
                                fileIndex: pdfs.length + i,
                                pageIndex: j,
                                pageProxy: page,
                                included: true
                            })));
                        }
                    });
                    Promise.all(newPages).then(allPages => setPages((pages) => [...pages, ...allPages]))
                }} more={pdfs.length > 0}/>
            {pdfs.length > 0 && (
                <>
                    <PagePreviews pages={pages} setPages={setPages}/>
                    <FormControlLabel sx={{fontSize: "20px"}} control={<Switch checked={pack}
                                                                               onChange={(e) => setPack(e.target.checked)}/>}
                                      label="A3に敷き詰める"/>
                    <FormControlLabel sx={{fontSize: "20px"}} control={<Switch checked={pack && bothSided}
                                                                               disabled={!pack}
                                                                               onChange={(e) => setBothSided(e.target.checked)}/>}
                                      label="両面印刷・冊子"/>
                    <FormControlLabel sx={{fontSize: "20px"}} control={<Switch checked={pack && bothSided && whiteFront}
                                                                               disabled={!(pack && bothSided)}
                                                                               onChange={(e) => setWhiteFront(e.target.checked)}/>}
                                      label="表紙空白"/>
                    <Exporter pdfs={pdfs} pages={pages} config={{a4toa3: pack, bothSided, whiteFront}}/>
                </>
            )}
        </div>
    )

}
export default FileManager;
