import * as React from 'react';
import {AppBar, Box, Toolbar, Typography, Button} from '@mui/material';

export default function MyAppBar() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        PDFシキツメ - コンビニプリントを半額に！
                    </Typography>
                    <Button href="https://twitter.com/na2hiro" color="inherit" sx={{textTransform: "none"}}>@na2hiro</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
