import React, {PropsWithChildren} from "react";
import {Card, CardContent, Grid, Typography} from "@mui/material";

const Banners = () => {
    return (<>
            <Grid container spacing={2} sx={{marginBottom: "16px"}}>
                <Banner head="💰節約">
                    A4文書をA3に敷き詰めて印刷代を約半額にします（※コンビニのネットプリントは白黒の場合A3まで同料金のため。<a href="https://www.printing.ne.jp/support/lite/pricelist_lite.html" target="_blank">参考1</a> <a href="https://networkprint.ne.jp/sharp_netprint/ja/howto_price_f.aspx" target="_blank">2</a>）
                </Banner>
                <Banner head="🌳エコ">
                    印刷しなくてもいいページを簡単に除外できます
                </Banner>
                <Banner head="😀簡単">
                    複数のPDFを一つにまとめて印刷できるため、ネットプリントで複数のID入力や選択といった手間が省けます。
                </Banner>
                <Banner head="🚅高速・🔐安全">
                    PDFの内容はあなたのデバイス内でのみ保持・編集され、サーバーには一切送信されません
                </Banner>
            </Grid>
        </>
    )
}
export default Banners;

type BannerProps = {
    head: React.ReactNode,
}
const Banner: React.FC<PropsWithChildren<BannerProps>> = ({head, children}) => {
    return (
        <Grid item xs={6} md={3}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {head}
                    </Typography>
                    <Typography sx={{mb: 1.5}} color="text.secondary">
                        {children}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}
