/*import {cssBundleHref} from "@remix-run/css-bundle";
import type {LinksFunction} from "@remix-run/node";
import {
    Links,
    LiveReload,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
} from "@remix-run/react";
 */
import MyAppBar from "~/app/components/MyAppBar";
/*
export const links: LinksFunction = () => [
    ...(cssBundleHref ? [{rel: "stylesheet", href: cssBundleHref}] : []),
];

export default function App() {
    return (
        <html lang="en">
        <head>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            <Meta/>
            <Links/>
        </head>
        <body style={{margin: 0}}>
        <header>
            <MyAppBar/>
        </header>
        <main style={{margin: "8px"}}>
            <Outlet/>
        </main>
        <footer>

        </footer>
        <ScrollRestoration/>
        <Scripts/>
        <LiveReload/>
        </body>
        </html>
    );
}*/

export function NonRemixApp({children}) {
    return (
        <>
        <header>
            <MyAppBar/>
        </header>
        <main style={{margin: "8px"}}>
            {children}
        </main>
        <footer>

        </footer>
        </>
    );
}
