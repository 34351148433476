import React, {SetStateAction, useEffect, useRef} from "react";
import {Page} from "~/app/components/FileManager";
import {previews} from "~/app/components/PagePreview.css";
import {Checkbox, Paper} from "@mui/material";

type Props = {
    page: Page;
    setInclusion: (included: boolean) => void;
}
const PagePreview: React.FC<Props> = ({page: {pageIndex, pageProxy, included, file}, setInclusion}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const scale = 0.5; // TODO: decide nicely
        const viewport = pageProxy.getViewport({scale: scale,});
        // Support HiDPI-screens.
        const outputScale = window.devicePixelRatio || 1;

        const canvas = canvasRef.current!;
        const context = canvas.getContext('2d');

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height = Math.floor(viewport.height) + "px";

        const transform = outputScale !== 1
            ? [outputScale, 0, 0, outputScale, 0, 0]
            : null;

        const renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport
        };
        pageProxy.render(renderContext);
    }, [pageProxy])
    return (
        <Paper sx={{position: "relative", opacity: included ? 1 : 0.6}} onClick={(e) => {
            setInclusion(!included)
        }}>
            <canvas ref={canvasRef}/>
            <span style={{
                position: "absolute",
                top: 0,
                left: 0,
                fontSize: "16px",
                padding: "4px"
            }}>{file.name} ({pageIndex + 1})</span>
            <Checkbox checked={included} size="medium"
                   sx={{position: "absolute", top: "-8px", right: "-8px" }}/>
        </Paper>
    )
}

export default PagePreview;

type PagePreviewsProps = { pages: Page[], setPages: React.Dispatch<SetStateAction<Page[]>>}

export const PagePreviews: React.FC<PagePreviewsProps> = ({pages, setPages}) => {
    return (
        <div className={previews}>
            {pages.map((page) => <PagePreview
                key={`${page.fileIndex}-${page.pageIndex}`} page={page}
                setInclusion={(included) => setPages((pages => {
                    const newPages = [...pages];
                    newPages[pages.indexOf(page)].included = included;
                    return newPages;
                }))}/>)}
        </div>
    )
}
